// src/components/Navbar.js
import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css"; // Create a corresponding CSS file

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          Blogs @ YouthXConnect
        </Link>
 
      </div>
    </nav>
  );
};

export default Navbar;
