// src/pages/BlogPost.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import ReactMarkdown from "react-markdown";
import "./BlogPost.css"; // Create a corresponding CSS file
import { motion } from 'framer-motion';

const pageTransition = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: { duration: 0.3 }
};

const BlogPost = () => {
  const { url } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      const setProgress = window.setProgress || (() => {});
      setProgress(30);
      try {
        const docRef = doc(db, "blogs", url);
        const docSnap = await getDoc(docRef);
        setProgress(70);
        if (docSnap.exists()) {
          setBlog(docSnap.data());
          setProgress(100);
        } else {
          setError("Blog post not found");
          setProgress(100);
        }
      } catch (error) {
        console.error("Error fetching blog: ", error);
        setError("Error loading blog post. Please try again later.");
        setProgress(100);
      }
      setLoading(false);
    };

    fetchBlog();
  }, [url]);

  if (loading) {
    return (
      <div className="blogpost-container">
        <div className="blogpost-loading">
          <div>Loading blog post...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="blogpost-container">
        <div className="blogpost-error">{error}</div>
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="blogpost-container">
        <div className="blogpost-error">Blog not found.</div>
      </div>
    );
  }

  const { title, content, publishedDate, publishedBy, thumbnailUrl } = blog;

  const date = new Date(publishedDate).toLocaleDateString();

  return (
    <motion.div 
      className="blogpost-container"
      {...pageTransition}
    >
      {thumbnailUrl && (
        <img src={thumbnailUrl} alt={title} className="blogpost-thumbnail" />
      )}
      <h1 className="blogpost-title">{title}</h1>
      <div className="blogpost-meta">
        <span>By {publishedBy}</span> | <span>{date}</span>
      </div>
      <div className="blogpost-content">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </motion.div>
  );
};

export default BlogPost;
