// src/pages/Home.js
import React, { useEffect, useState } from "react";
import { collection, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import BlogCard from "../components/BlogCard";
import "./Home.css"; // Create a corresponding CSS file

const Home = () => {
  const [blogs, setBlogs] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const BLOGS_PER_PAGE = 6; // Changed from 3 to 6

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      let q;
      if (lastDoc) {
        q = query(
          collection(db, "blogs"),
          orderBy("publishedDate", "desc"),
          startAfter(lastDoc),
          limit(BLOGS_PER_PAGE)
        );
      } else {
        q = query(
          collection(db, "blogs"),
          orderBy("publishedDate", "desc"),
          limit(BLOGS_PER_PAGE)
        );
      }

      const querySnapshot = await getDocs(q);
      const fetchedBlogs = [];
      querySnapshot.forEach((doc) => {
        fetchedBlogs.push({ id: doc.id, ...doc.data() });
      });

      // Only spread previous blogs if we're loading more (lastDoc exists)
      setBlogs((prev) => lastDoc ? [...prev, ...fetchedBlogs] : fetchedBlogs);
      
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastDoc(lastVisible);

      if (querySnapshot.docs.length < BLOGS_PER_PAGE) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching blogs: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="home-container">
      <h1 className="home-title">Latest Blogs</h1>
      <div className="blogs-grid">
        {blogs.map((blog) => (
          <BlogCard key={blog.id} blog={blog} />
        ))}
      </div>
      {hasMore && (
        <button className="load-more-btn" onClick={fetchBlogs} disabled={loading}>
          {loading ? "Loading..." : "Load More"}
        </button>
      )}
      {!hasMore && <p>No more blogs to display.</p>}
    </div>
  );
};

export default Home;
