// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import { AnimatePresence } from 'framer-motion';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import BlogPost from "./pages/BlogPost";
import "./App.css"; // Global styles

function AppContent() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/:url" element={<BlogPost />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  const [progress, setProgress] = useState(0);

  return (
    <Router>
      <LoadingBar
        color='#2563eb'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        shadow={true}
        height={3}
      />
      <Navbar />
      <div className="main-content">
        <AppContent />
      </div>
      <Footer />
    </Router>
  );
}

export default App;
