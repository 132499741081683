// src/components/Footer.js
import React from "react";
import "./Footer.css"; // Create a corresponding CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
  
        <div className="footer-copy">
          &copy; {new Date().getFullYear()} YouthXConnect. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
