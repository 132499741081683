// src/components/BlogCard.js
import React from "react";
import { Link } from "react-router-dom";
import "./BlogCard.css"; // Create a corresponding CSS file

const BlogCard = ({ blog }) => {
  const { title, shortDescription, thumbnailUrl, url, publishedDate } = blog;

  // Format the date
  const date = new Date(publishedDate).toLocaleDateString();

  return (
    <div className="blog-card">
      <img src={thumbnailUrl} alt={title} className="blog-thumbnail" />
      <div className="blog-content">
        <h2 className="blog-title">{title}</h2>
        <p className="blog-description">{shortDescription}</p>
        <div className="blog-footer">
          <span className="blog-date">{date}</span>
          <Link to={`/${url}`} className="read-more">
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
